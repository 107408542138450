
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import { setPageTitle } from "@/core/helpers/dom";

export default defineComponent({
  name: "VouchersResourcePage",
  components: { ResourcePage, ResourceTable },
  setup() {
    setPageTitle("Vouchers");
  },
});
