<template>
  <resource-page title="Vouchers" resource="voucher">
    <template #default="{ grid }">
      <resource-table v-if="grid != null" :resource="'voucher'" :grid="grid">
        <template #column-code="{ row }">
          <div :title="row.code">
            <strong>{{ row.code }}</strong>
          </div>
        </template>
        <template #column-voucher_value="{ row }">
          <div :title="row.voucher_value">
            <strong>{{ $filters.currencyEUR(row.voucher_value) }}</strong>
          </div>
        </template>
      </resource-table>
    </template>
  </resource-page>
</template>
<script lang="ts">
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import { setPageTitle } from "@/core/helpers/dom";

export default defineComponent({
  name: "VouchersResourcePage",
  components: { ResourcePage, ResourceTable },
  setup() {
    setPageTitle("Vouchers");
  },
});
</script>

<style scoped></style>
